
import { defineComponent, onMounted, ref } from 'vue';
import { formatLocalTime } from '@/utils/format-time';

import { useWithdrawals } from '@/composables/api';
import { SortOrder, WithdrawalSortKey } from '@/apis';
import { WithdrawlStatus } from '@/interfaces';
import { getStatusTagType } from '@/utils/render';
import PanelGroup from './components/PanelGroup.vue';
import { getWithdrawalOverview } from '@/apis/withdrawal';

export default defineComponent({
  components: {
    PanelGroup
  },
  setup() {
    // query items
    const page = ref(1);
    const pageSize = ref(15);
    const sortOrder = ref<SortOrder>(SortOrder.DESCENDING);
    const sortKey = ref<WithdrawalSortKey>(WithdrawalSortKey.CREATED_AT);
    const withdrawalOverview = ref();

    const { data, isLoading, isFetching } = useWithdrawals({ page, pageSize, sort: sortOrder, sortKey });
    const fetchWithdrawalOverview = async() => {
      const { data } = await getWithdrawalOverview();
      withdrawalOverview.value = data;
    };

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    onMounted(() => {
      fetchWithdrawalOverview();
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      sortChange,
      formatLocalTime,
      getStatusTagType,
      WithdrawlStatus,
      withdrawalOverview
    };
  }
});
